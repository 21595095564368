import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA
} from "@angular/core";
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';

/* [ Custom Pipe ] */
import {
  TruncatePipe,
  PhonePrefixPlusPipe,
  SecureImagesPipe
} from "./app.pipe";
/* [ Shared Plugins ] */
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SlideshowModule } from "ng-simple-slideshow";
/* [ shared Module ] */
import {
  DecimalNumberOnlyDirective,
  NumberOnlyDirective,
  CompareValidatorDirective,
  NoWhitespaceDirective,
  IBMPhoneDirective,
  NumberZeroGreaterDirective
} from "./app.directive";


import { HeaderComponent } from "../pages/header/header.component";
import { FooterComponent } from "../pages/footer/footer.component";
import { TopMenuComponent } from "../pages/top-menu/top-menu.component";
import { FooterFirstComponent } from "../pages/footer-first/footer-first.component";
import { OhmyeventlistComponent } from '../ohmyeventlist/ohmyeventlist.component';

import { SocialLoginComponent } from "../pages/social-login/social-login.component";

@NgModule({
  imports: [
    SlickCarouselModule, 
    SlideshowModule,
    CommonModule,
    RouterModule
  ],
  declarations: [
    DecimalNumberOnlyDirective,
    NumberOnlyDirective,
    CompareValidatorDirective,
    NoWhitespaceDirective,
    TruncatePipe,
    PhonePrefixPlusPipe,
    SecureImagesPipe,
    IBMPhoneDirective,
    HeaderComponent,
    FooterComponent,
    TopMenuComponent,
    NumberZeroGreaterDirective,
    FooterFirstComponent ,
    OhmyeventlistComponent,
    SocialLoginComponent
  ],
  providers: [],
  bootstrap: [],
  exports: [
    DecimalNumberOnlyDirective,
    NumberOnlyDirective,
    CompareValidatorDirective,
    NoWhitespaceDirective,
    TruncatePipe,
    PhonePrefixPlusPipe,
    SecureImagesPipe,
    IBMPhoneDirective,
    NumberZeroGreaterDirective,
    SlickCarouselModule,
    SlideshowModule,
    HeaderComponent,
    FooterComponent,
    TopMenuComponent,
    FooterFirstComponent,
    OhmyeventlistComponent,
    SocialLoginComponent
  ]
})
export class AppSharedModule {}
