import { Component, OnInit} from "@angular/core";
import { Location } from "@angular/common";
import { BrowserModule, Title, Meta } from "@angular/platform-browser";
import { ActivatedRoute,  Router } from "@angular/router";
import { AppService } from "../../shared/app.service";
import { AppComponent } from "../../app.component";
/* [ Spinner ] */
import { NgxSpinnerService } from "ngx-spinner";
import { NgModule } from '@angular/core';

// import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/takeWhile";
// import "rxjs/add/observable/timer";

const url = [
  "../../../assets/js/widgets.js",
  "../../../assets/js/profile.js"
];

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent extends AppComponent implements OnInit {
  loadAPI: Promise<any>;
  alive = true;
  currentYear:number;
  postalcode:number;
  public email: string;
  public treeData = [];
  public slides = [
    { img: "../../assets/images/partner_img1.png" },
    { img: "../../assets/images/partner_img2.png" },
    { img: "../../assets/images/partner_img3.png" },
    { img: "../../assets/images/partner_img4.png" },
    { img: "../../assets/images/partner_img1.png" },
    { img: "../../assets/images/partner_img2.png" }
  ];

  public slideConfig: any = {
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true
  };

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    appService: AppService,
    location: Location,
    spinner: NgxSpinnerService
  ) {
    super(activatedRoute, router, appService, location, spinner);
  }

  ngOnInit() {
    this.loadScript();
    this.currentYear = new Date().getFullYear();
  }

  getMenuTree() {
    this.spinner.show();
    this.appService.getAll("/admin/menu/bottomtree").subscribe(res => {
      if (res["isSuccess"] == true) {
        this.treeData = (res as any).data;
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    });
  }

  goToCms(event: Event, endpoint) {
    event.preventDefault();
    if (endpoint != "" && endpoint != null) {
      this.router.navigate(
        [this._const("PATH.USERS.CMS.SELF") + "/" + endpoint],
        {
          queryParams: {}
        }
      );
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }

  loadScript() {
    for (let i = 0; i < url.length; i++) {
      let node = document.createElement("script");
      node.src = url[i];
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(node);
    }
  }

  subscribenewsletter() {
    
    // var postalCode= {
    //   postalcode: document.getElementById('postalcode')["value"]
    // };
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(document.getElementById('email')["value"]))
    {
      this.spinner.show();
    var email = {
      email: document.getElementById('email')["value"]
    };
      if(email){

        this.appService.create("/user/subscribenewsletter", email).subscribe(val => {
          if (val.isSuccess == true) {
            this._showAlertMessage(
              "alert-success",
              "souscrit avec succès"
            );
            this.spinner.hide();
          } else {
            this._showAlertMessage("alert-danger", "Veuillez saisir une adresse e-mail valide");
            this.spinner.hide();
          } 
        });
      }
    }else{
      this._showAlertMessage("alert-danger", "Veuillez saisir une adresse e-mail valide");
      
    }  
  }
}
