import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// search module
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
/* Spinner */
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FullCalendarModule } from "@fullcalendar/angular";
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angular-6-social-login";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import { RoutingPath } from "./shared/app.constant";
/* [ Token Interceptor ] */
import { AuthInterceptor } from "./shared/auth-interceptor";
import { ResponseInterceptor } from "./shared/response-interceptor";
/* [ Service ] */
import { AppService } from "./shared/app.service";
/* [ Shared Module ] */
import { AppSharedModule } from "./shared/app.shared.module";
import { HomeComponent } from "./pages/home/home.component";
import { SocialUserComponent } from "./pages/social-user/social-user.component";
import { OhMyCoachComponent } from "./oh-my-coach/oh-my-coach.component";
import { OhMyCoachDetailNewComponent } from "./oh-my-coach-detail-new/oh-my-coach-detail-new.component";
import { CoachDetailComponent } from "./coach-detail/coach-detail.component";
import { OhMyEventNewComponent } from "./oh-my-event-new/oh-my-event-new.component";
import { OhMyCoachNewComponent } from "./oh-my-coach-new/oh-my-coach-new.component";
import { OhMyCoachServiceComponent } from "./oh-my-coach-service/oh-my-coach-service.component";

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';

registerLocaleData(localeFr, "fr");
const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full"
  },
  {
    path: RoutingPath.SOCIAL_USER,
    component: SocialUserComponent
  },
  {
    path: RoutingPath.COUCH_DETAIL,
    component: CoachDetailComponent
  },
  {
    path: RoutingPath.OH_MY_COACH,
    component: OhMyCoachComponent
  },
  {
    path: RoutingPath.OH_MY_COACH_NEW,
    component: OhMyCoachNewComponent
  },
  {
    path: RoutingPath.OH_MY_COACH_SERVICE,
    component: OhMyCoachServiceComponent
  },
  {
    path: RoutingPath.OH_MY_EVENT_NEW,
    component: OhMyEventNewComponent
  },
  {
    path: RoutingPath.OH_MY_COACH_DETAIL,
    component: OhMyCoachDetailNewComponent
  },
  {
    path: RoutingPath.OH_MY_COACH_DETAIL_NEW,
    component: OhMyCoachDetailNewComponent
  },

  {
    path: RoutingPath.COACH.SELF,
    loadChildren: () =>
      import("./model/coach/coach.module").then(mod => mod.CoachModule)
  },
  {
    path: RoutingPath.USER.SELF,
    loadChildren: () =>
      import("./model/user/user.module").then(mod => mod.UserModule)
  },
  {
    path: RoutingPath.STATICPAGE.SELF,
    loadChildren: () =>
      import("./model/static/static.module").then(mod => mod.StaticModule)
  },
  {
    path: RoutingPath.GENERAL.SELF,
    loadChildren: () =>
      import("./model/general/general.module").then(mod => mod.GeneralModule)
  },
  {
    path: RoutingPath.ADMIN.SELF,
    loadChildren: () =>
      import("./model/admin/admin.module").then(mod => mod.AdminModule)
  },
  {
    path: RoutingPath.OH_MY_COACH_DETAIL_NEW,
    component: OhMyCoachDetailNewComponent
  },
  {
    path: "**",
    component: HomeComponent
  }
];

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig([
    {
      id: FacebookLoginProvider.PROVIDER_ID,
      provider: new FacebookLoginProvider("159591298915748")
    },
    {
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider('815994758965-or2fp65ukk087cfcrn1qqdf0clpbg2kb.apps.googleusercontent.com') //181259375586-h61doj31ss9v83udpv3h0orv122s0dg6.apps.googleusercontent.com 
    }
  ]);
  return config;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SocialUserComponent,
    OhMyCoachComponent,
    OhMyCoachNewComponent,
    OhMyCoachServiceComponent,
    OhMyEventNewComponent,
    CoachDetailComponent,
    OhMyCoachDetailNewComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FullCalendarModule,
    RouterModule.forRoot(routes),
    AppSharedModule,
    NgxSpinnerModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng2SearchPipeModule,
    ScrollingModule,
    InfiniteScrollModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    MatSelectModule
  ],
  providers: [
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "fr" },
    { provide: AuthServiceConfig, useFactory: getAuthServiceConfigs },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent],
  exports: [
    // TopMenuComponent


    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }