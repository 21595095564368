import {
  Component,
  OnInit,
  ɵConsole,
  AfterViewInit,
  OnDestroy,
  HostListener
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { AppService } from "../shared/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "../app.component";
import { Location } from "@angular/common";
import { BrowserModule, Title, Meta } from "@angular/platform-browser";
import * as _ from "underscore";
import * as $ from "jquery";
import * as L from "leaflet";
import { HttpParams } from "@angular/common/http";
import {Observable,  of} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: "app-oh-my-coach-new",
  templateUrl: "./oh-my-coach-new.component.html",
  styleUrls: ["./oh-my-coach-new.component.scss"]
})
export class OhMyCoachNewComponent extends AppComponent implements OnInit {

 
  public min = new Date();
  public date: any = "";
  public respon: any;
  showMyMap: boolean = true;
  showMapCSS: string = "none";
  public search: any = {
    date: "",
    ville: "",
    rayon: "0",
    course: ""
  };

  public course: any = {
    CoursIndividuel: false,
    CoursCollectifOndemand: false,
    CoursCollectifClub: false
  };

  //public latlongcurrent: any;
  map: any;
  mapvalues: any;
  lat: any;
  lang: any;
  curentlat: any;
  curentlang: any;
  co_or_gps: any;
  numbers = [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20
  ];
  public service: any;

  private allItems: any[];
  public villes: any[];
  public code_postal: any[];
  searchText;
  
 
  // pager object
  pager: any = {};
  public rowDataCollection: any = [];
  public rowCollection: any = [];
  public rowCollectionCode: any = [];
  private noOfItemsToShowInitially: number = 100;
  private itemsToLoad: number = 100;
  public itemsToShow = this.rowCollection.slice(0, this.noOfItemsToShowInitially);
  public isFullListDisplayed: boolean = false;
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{5}$";
  isValidFormSubmitted = false;
  // paged items
  pagedItems: any[];

  //results: string[];
  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    appService: AppService,
    location: Location,
    spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaTagService: Meta,
  ) {
    super(activatedRoute, router, appService, location, spinner);
  }
  ngOnInit() {

    
    this.getcoach();
    this.getVille();
    //SEO
    this.titleService.setTitle("Ohmytennis | offre de service coach tennis");
    this.metaTagService.removeTag('name=title');
    this.metaTagService.removeTag('name=keywords');
    this.metaTagService.removeTag('name=description');
    this.metaTagService.updateTag({ name: 'title', content: 'Ohmytennis | offre de service coach tennis'});
    this.metaTagService.addTag({ name: 'keywords', content: 'Cours particulier de tennis ou collectif à la demande' });
    this.metaTagService.addTag({ name: 'keywords', content: 'Cours collectifs de tennis à l’année' });
    this.metaTagService.updateTag({ name: 'description', content: "Jouez sur les terrains grâce à nos cours de tennis particuliers ou collectifs en club. En effet vous avez la possibilité de progresser toute l’année en trouvant un prof disponible et professionnel. Rendez vous dès maintenant pour commencer votre première leçon de tennis" }, 'name="description"');
  }

 
  ngAfterViewInit(): void {
    localStorage.getItem("onmytennis");
  }



  getcoach() {
    this.spinner.show();
    setTimeout(() => {
      var ohmycoach_menu_type = localStorage.getItem("ohmycoach-menu-type");
      var codepostal = localStorage.getItem("codepostal");
      if (ohmycoach_menu_type == "") {
        var pcode = localStorage.getItem("onmytennis");
        if (pcode) {
          var postalCode = JSON.parse(JSON.parse(pcode));
          this.search.ville = postalCode.postalCode;
        }
        this.search.course = "";
      }
      else {
        this.selectedOneCheckbox(ohmycoach_menu_type);
        this.search.course = ohmycoach_menu_type;
        localStorage.setItem("Course", ohmycoach_menu_type);
        // var pcode = localStorage.getItem("onmytennis");
        // if (pcode) {
        //   var postalCode = JSON.parse(JSON.parse(pcode));
        //   this.search.ville = postalCode.postalCode;
        // }
        var new_data = localStorage.getItem("updatedData");
        if (new_data) {
          var postalCode = JSON.parse(new_data);
          this.search.ville = postalCode.postalCode;  
        }
      }
      this.appService
        .getAll("/coach/searchByCoach", this.search)
        .subscribe(data => {
          if (data && data["data"]) {
            this.allItems = (data as any).data.coach_list;
            this.spinner.hide();
            localStorage.removeItem("codepostal");
            this.setPage(1);
          }

        });
    }, 1000);
  }

  selectedOneCheckbox(id) {
    this.spinner.show();

    setTimeout(() => {
      if (id == "CoursIndividuel") {
        this.search.course = "";
        let individual = document.getElementById(
          "check_CoursIndividuel"
        ) as HTMLInputElement;
        individual.checked = true;
        let ondemand = document.getElementById(
          "check_CoursCollectifOndemand"
        ) as HTMLInputElement;
        ondemand.checked = false;
        let club = document.getElementById(
          "check_CoursCollectifClub"
        ) as HTMLInputElement;
        club.checked = false;
        this.search.course = "CoursIndividuel";

      } else if (id == "CoursCollectifOndemand") {
        this.search.course = "";
        let individual = document.getElementById(
          "check_CoursIndividuel"
        ) as HTMLInputElement;
        individual.checked = false;
        let ondemand = document.getElementById(
          "check_CoursCollectifOndemand"
        ) as HTMLInputElement;
        ondemand.checked = true;
        let club = document.getElementById(
          "check_CoursCollectifClub"
        ) as HTMLInputElement;
        club.checked = false;
        this.search.course = "CoursCollectifOndemand";
      } else if (id == "CoursCollectifClub") {
        this.search.course = "";
        let individual = document.getElementById(
          "check_CoursIndividuel"
        ) as HTMLInputElement;
        individual.checked = false;
        let ondemand = document.getElementById(
          "check_CoursCollectifOndemand"
        ) as HTMLInputElement;
        ondemand.checked = false;
        let club = document.getElementById(
          "check_CoursCollectifClub"
        ) as HTMLInputElement;
        club.checked = true;
        this.search.course = "CoursCollectifClub";
      }

      localStorage.setItem("ohmycoach-menu-type", id);
      localStorage.setItem("Course", id);
      this.location.replaceState(
        this._const("PATH.OH_MY_COACH_NEW") + "?course=" + id
      );
      var pcode = localStorage.getItem("onmytennis");
      var new_data = localStorage.getItem("updatedData");
      // console.log(new_data);
      // console.log(pcode)
      // if (pcode) {
      //   var postalCode = JSON.parse(JSON.parse(pcode));
      //   this.search.ville = postalCode.postalCode;  
      // }
      if (new_data) {
        var postalCode = JSON.parse(new_data);
        this.search.ville = postalCode.postalCode;  
      }
      const search = {
        course: id,
        date: "",
        ville: this.search.ville,
        rayon: "0",
        city: ""
      };
      this.searchByCoachClick(search);
      this.spinner.hide();
    }, 1000);
  }

  findCoach(search) {
    this.spinner.show();
    localStorage.setItem("Course", search.course);
    if (this.date != "") search.date = this.formatDate(this.date);
    this.appService.getAll("/coach/findyourCoach", search).subscribe(data => {
      if (data && data["data"]) {
        this.respon = (data as any).data.coach_list;
        this.spinner.hide();
      }
    });
  }

  removeLastComma(str) {
    return str.replace(/,(\s+)?$/, "");
  }

  onFilterChange(eve: any) {
    this.search.individual = !this.search.individual;
  }

  searchByCoachClick(search) {

    this.spinner.show();
    let selectorVilleID = document.getElementById("ville") as HTMLInputElement;
    selectorVilleID.style.border = "";
    this.appService.getAll("/coach/searchByCoach", search).subscribe(data => {
      if ((data as any).isSuccess == true) {
        setTimeout(() => {
          this.allItems = (data as any).data.coach_list;

          if (this.allItems.length > 0) {
            this.pager.totalPages = this.allItems.length;
            this.setPage(1);
          } else {
            this.pager.totalPages = this.allItems.length;
            this.pagedItems = [];
            this.setPage(1);
          }
          this.spinner.hide();
          window.scroll({
            top: 850,
            left: 0,
            behavior: "smooth"
          });
        }, 1000);
      }
    });
  }

  villeSelected(city: string) {   
    console.log(city);
    var city=city;
    this.spinner.show();
    var course_type = localStorage.getItem("ohmycoach-menu-type"); 
    //this.search.ville=city;   
    
    this.appService.getAll("/city/getcityPostal/" + city).subscribe((data) => {
        if (data && data["data"]) {
          
          var dat = (data as any).data.city_list[0];
          console.log(dat.Code_postal); 
          this.search.ville = dat.Code_postal;
        }  
      });   
      
      var searchville = {
        ville: this.search.ville,
        course: course_type,
        rayon: '0'
      };
                    
    this.appService
      .getAll("/coach/searchByCoachList", searchville)
      .subscribe(data => {
        if ((data as any).isSuccess == true) {
          this.allItems = (data as any).data.coach_list;
          if (this.allItems.length > 0) {
            this.pager.totalPages = this.allItems.length;
            this.setPage(1);
          } else {
            this.pager.totalPages = this.allItems.length;
            this.pagedItems = [];
            this.setPage(1);
          }
          this.spinner.hide();
          window.scroll({
            top: 850,
            left: 0,
            behavior: "smooth"
          });
        }
      });
  }

  searchByCoach(search) {
    this.spinner.show();
    this.isValidFormSubmitted = true;
    if (search.ville != "" && search.ville != null) {
      let selectorVilleID = document.getElementById(
        "ville"
      ) as HTMLInputElement;
      selectorVilleID.style.border = "";
    } else {
      let villeID = document.getElementById("ville") as HTMLInputElement;
      villeID.setAttribute("tabIndex", "-1");
      villeID.focus();
      let selectorVilleID = document.getElementById(
        "ville"
      ) as HTMLInputElement;
      selectorVilleID.style.border = "1px solid red";

      this.spinner.hide();
      window.scroll({
        top: 850,
        left: 0,
        behavior: "smooth"
      });
      return;
    }
    this.appService
      .getAll("/coach/searchByCoachList", search)
      .subscribe(data => {
        this.spinner.hide();
        if ((data as any).isSuccess == true) {
          this.allItems = (data as any).data.coach_list;
          if (this.allItems.length > 0) {
            this.pager.totalPages = this.allItems.length;
            this.setPage(1);
          } else {
            this.pager.totalPages = this.allItems.length;
            this.pagedItems = [];
            this.setPage(1);
          }
          this.spinner.hide();
          window.scroll({
            top: 850,
            left: 0,
            behavior: "smooth"
          });
        }
      });
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 4) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = _.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  setPage(page: number) {
    // this.spinner.show();
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    // get pager object from service
    this.pager = this.getPager(this.allItems.length, page);

    // get current page of items
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    window.scroll({
      top: 850,
      left: 0,
      behavior: "smooth"
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  gotoCouch(res, id) {
    if (localStorage.getItem("ohmycoach-menu-type") !== null) {
      if (localStorage.getItem("onmytennis") !== null) {
        var data = JSON.stringify(res);
        localStorage.setItem("Coach", data);
        localStorage.setItem("Coach_id", id);
        this.router.navigate(["/coachdetail"]);
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      this.router.navigate(["ohmycoachdetail"], {
        queryParams: {
          id,
          course: this.search.course
        }
      });
    }
  }


  gotoService(res, id) {

    if (localStorage.getItem("ohmycoach-menu-type") !== null) {
      if (localStorage.getItem("onmytennis") !== null) {
        var data = JSON.stringify(res);
        this.spinner.show();
        localStorage.setItem("Coach", data);
        localStorage.setItem("CoachService", JSON.stringify(res["Coach_Services"]));
        var user = localStorage.getItem("onmytennis");
        var search = {
          User_Email: res.Coach_Email,
        };
        this.appService
          .create("/user/getuserbyid", search)
          .subscribe(data => {
            if (data.isSuccess == true) {
              let Coach_id = data.data.User_list[0].id;
              localStorage.setItem("Coach_id", Coach_id);
              this.spinner.hide();
              this.router.navigate(["/ohmycoachservice"]);


            } else {

              this.router.navigate(["ohmycoachdetail"], {
                queryParams: {
                  id,
                  course: this.search.course
                }
              });
            }


          });
      } else {
        this.router.navigate(["/login"]);
      }
    } else {
      this.router.navigate(["ohmycoachdetail"], {
        queryParams: {
          id,
          course: this.search.course
        }
      });
    }
  }

  goToCouchDetail(id) {
    this.router.navigate(["ohmycoachdetail"], {
      queryParams: {
        id,
        course: this.search.course
      }
    });
  }

  getVille() {
    this.appService
      .getAll("/city/getcityName")
      .subscribe(data => {
        if (data && data["data"]) {

          this.villes = (data as any).data.city_list;

          this.rowCollection = [];
         
          for (var row = 0; row < this.villes.length; row++) {
            this.rowCollection.push({
              cities: this.villes[row].Nom_commune,
              code_postal: this.villes[row].Code_postal

            });
            this.onScroll();
          }


          this.spinner.hide();

        }
      });
  }

  onScroll() {
    if (this.noOfItemsToShowInitially <= this.rowCollection.length) {
      // Update ending position to select more items from the array
      this.noOfItemsToShowInitially += this.itemsToLoad;
      this.itemsToShow = this.rowCollection.slice(0, this.noOfItemsToShowInitially);
    } else {
      this.isFullListDisplayed = true;
    }

  }
  

}
