import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ohmyeventlist',
  templateUrl: './ohmyeventlist.component.html',
  styleUrls: ['./ohmyeventlist.component.scss']
})
export class OhmyeventlistComponent implements OnInit {
  public coachService: any = [];
  @Input() coachData = {};
  public coach_id = "";
  constructor(public router: Router) { }

  ngOnInit() {
    this.coachService = localStorage.getItem("CoachService");
  }
  gotoCouchDetail(ser, res) {
    if (localStorage.getItem("onmytennis") !== null) {
      var data = JSON.stringify(res);
      localStorage.setItem("Coach", data);
      localStorage.setItem("Course", ser);
      localStorage.setItem("ohmycoach-menu-type", ser);
      this.router.navigate(["/ohmycoachservice", { 'course': ser }]);
    } else {
      this.router.navigate(["/login"]);
    }
  }
  getEventData(ser) {

    if (localStorage.getItem("onmytennis") !== null) {
      localStorage.setItem("Course", ser);
      localStorage.setItem("ohmycoach-menu-type", ser);
      this.router.navigate(["/stage-tennis", { 'course': ser }]);
    } else {
      this.router.navigate(["/login"]);
    }
  }


}
