import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  ElementRef,
} from "@angular/core";
import { AppService } from "../shared/app.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";
import { FullCalendarComponent } from "@fullcalendar/angular";
import dayGridView from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import * as moment from "moment";
import * as $ from "jquery";
import * as L from "leaflet";
import Swal from "sweetalert2";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import frLocale from '@fullcalendar/core/locales/fr'; //French language
import esLocale from '@fullcalendar/core/locales/en-au';

@Component({     
  selector: "app-coach-detail",
  templateUrl: "./coach-detail.component.html",
  styleUrls: ["./coach-detail.component.scss"],
})

export class CoachDetailComponent implements OnInit {

  public courseActive = "";
  public reserveData: any = [];
  public selectedCity: any = null;
  map: any;
  mapvalues: any;
  lat: any;
  lang: any;
  curentlat: any;
  curentlang: any;
  public alertMsg: any = {
    type: "",
    msg: "",
    show: false,
  };
  public min = new Date();
  locales = [frLocale, esLocale];
  public calender = [];
  calendarPlugins = [dayGridView, interactionPlugin];
  // calendarPlugins = [dayGridView];
  public UserAviablility: any = [];
  calendarOptions = {
    format: "DD-MM-YYYY",
    firstWeekdaySunday: false,
    selectable: true,
    views: {
      dayGridMonth: {
        eventLimit: 3,
      },
    },
  };
  public str: any = null;
  public timeslot: any = [];
  public timeslotdisplay: boolean = false;
  public booking: any = {
    Coach_ID: "",
    user_Id: "",
    payment_Id: 0,
    status: "",
    bookingDate: "",
    bookingCourse: "",
    amount: "",
    coach_Email: "",
    user_Email: "",
    coach_Name: "",
    user_Name: "",
    paymentStatus: "",
    session: [],
    bookingDateRange: "",
  };

  public course = localStorage.getItem("Course");

  public coach_detail = {
    Coach_Fname: "",
    Coach_ID: "",
    Coach_Lname: "",
    Coach_Email: "",
    Coach_Phone: "",
    InstagramURL: "",
    FacebookURL: "",
    TwitterURL: "",
    Coach_Description: "",
    Coach_Experience: "",
    Coach_Rayon: "",
    Coach_Price: "",
    Coach_Services: "",
    Coach_PriceX10: "",
    Coach_Bank_Name: "",
    Coach_Bank_ACCNum: "",
    Branch_Code: "",
    Coach_Bank_City: "",
    Coach_payment_type: "",
    Coach_transport: "",
    Coach_Image: "../../assets/images/profile_blackwhite.png",
    Coach_Resume: "",
    ResumeName: "",
  };

  public course_demand = {
    Price_2pl_1hr: "",
    Price_3pl_1hr: "",
    Price_4pl_1hr: "",
    Price_5pl_1hr: "",
    Price_6pl_1hr: "",
    Price_2pl_10hr: "",
    Price_3pl_10hr: "",
    Price_4pl_10hr: "",
    Price_5pl_10hr: "",
    Price_6pl_10hr: "",
    person: "",
  };

  public book_coach = {
    P_BookingCourseID: "",
    P_CoachId: "",
    P_CourseId: "",
    P_Date: "",
    P_Hour: "",
    P_UserId: "",
    P_Amount: "",
    P_Remarks: "",
  };

  public voiture: boolean = false;
  public bus: boolean = false;
  public metro: boolean = false;
  public rer: boolean = false;
  public tram: boolean = false;

  public moment_date: any;
  public temps: string = "";
  public Video: any;
  public Clubcourse: any;
  public Weekday: any;
  public resumeURL: any;
  public applicationtype: any;
  public Description: any;
  public slot: any;
  public slides: any;
  public slidecnt: any;
  public session = [];
  public bookArray = [];
  public bookingDate: any;
  public Indiv_1hr: any;
  public Indiv_10hr: any;
  public eventname: any;
  public Mode_of_Transport: any;
  public location: any;
  public pincode: any;
  public price: any;
  public Amt = 0;
  public person = "";
  public book_person = 0;
  public booked_user = [];
  public bookedCoachSide = [];
  public allocate_person = "";
  public availablity: any;
  public Courseavailablity: any;
  public is10Hr = false;
  public step_2 = false;
  public step_3 = false;
  public IsChecked = true;
  public showclub = false;
  public Timeslotdata = {
    Start_Date: "",
    Coach_ID: "",
    Course: "",
  };
  public coachService: any = [];

  public isClub;
  public isOnDemand;
  public isIndiv;
  private routedComponent: CoachDetailComponent;
  particulersform: FormGroup;
  coachdetailform: FormGroup;
  cutomerReviewCollection: any = [];
  showMsg: boolean = false;
  showsuccessMsg: boolean = false;
  count_id: any;

  public coach_detail_data = {};
  searchQuery = "/coachdetail";
  previousSearchQuery = "";
  constructor(
    public sanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public appService: AppService,
    public Location: Location,
    public spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    this.slidecnt = 4;
  }

  public setRoutedComponent(componentRef: CoachDetailComponent): void {
    this.routedComponent = componentRef;
  }
  ngAfterViewInit(): void {
    var datas = this.UserAviablility;
    var today = document.getElementsByClassName("fc-today-button");
    if (today) today[0].innerHTML = "Aujourd'hui";
  }
  @ViewChild("fileInput", { static: false }) fileInput: ElementRef;
  @ViewChild("calendar", { static: false })
  calendarComponent: FullCalendarComponent;
  @Output() closeModalEvent = new EventEmitter<boolean>();

  ngOnInit() {
    this.coachdetailform = this.formBuilder.group({
      codepostal: [null, [Validators.required]],
      date: [null, [Validators.required]],
    });

    this.particulersform = this.formBuilder.group({
      comments: [null, [Validators.required]],
    });

    this.getcurrentcordinates();

    this.spinner.show();
    this.price = 0;
    if (window.innerWidth > 1024) {
      this.slidecnt = 4;
    } else if (window.innerWidth > 768) {
      this.slidecnt = 3;
    } else {
      this.slidecnt = 2;
    }
    this.coachSlider();

    var titile = document.getElementsByClassName("brand");
    if (titile.length > 0) titile[0].innerHTML = "MON CALENDRIER";

    // Getting Max_Price of selected course for Summary
    var coach = JSON.parse(localStorage.getItem("Coach"));
    this.coach_detail_data = coach;
    var Coach_ID = {
      coachId: coach.Coach_Id,
      course: this.course
    };
    var course = localStorage.getItem("Course");
    this.coachService = localStorage.getItem("CoachService");
    this.getReviewDetails(course);

    
    this.appService
      .getAll("/coach/CoachCalendarAvaiabilityForUser", Coach_ID)
      .subscribe((response) => {
        if ((response as any).data.coach_list.length > 0) {
          if (response && response["data"]) {
            var dat = (response as any).data.coach_list;
            this.calender = (response as any).data.coach_list;
            $('.fc-content .fc-time').css('display', 'none');
           
          }
        }
      });

    //when clicking the event
    
    this.appService
      .getAll("/course/courseIsIndivIsOnDemandIsClub", Coach_ID)
      .subscribe((response) => {
        const isIndiIsOnDemandIsClub = (response as any).data;
        this.isIndiv = isIndiIsOnDemandIsClub.indivCount;
        this.isOnDemand = isIndiIsOnDemandIsClub.onDemandCount;
        this.isClub = isIndiIsOnDemandIsClub.clubCount;
      });

    if (course == "CoursIndividuel") {
      var coach_id = {
        coachId: coach.Coach_Id,
        indv_id: coach.id,
      };

      this.appService
        .getAll("/course/getindividualcourse", coach_id)
        .subscribe((response) => {
          if ((response as any).data.course.length > 0) {
            if (response && response["data"]) {
              var dat = (response as any).data.course[0];
              this.eventname = dat.Eventname;
              this.price = dat.Price_min;
              this.Indiv_1hr = dat.Price_min;
              this.Indiv_10hr = dat.Price_max;
              this.Video = dat.Video;
              this.Description = dat.Description;
              this.pincode = dat.Postalcode;
              this.location = dat.Location;
              this.Mode_of_Transport=dat.Mode_of_Transport;
              this.mapvalues = eval("[" + dat.coordonnees_gps + "]");
              this.lat = this.mapvalues[0].toFixed(3);
              this.lang = this.mapvalues[1].toFixed(3);
              this.mapintigration(this.mapvalues, this.location);
              this.appService
                .getAll("/city/" + dat.Postalcode)
                .subscribe((response) => {
                  if (response && response["data"]) {
                    this.selectedCity = (response as any).data.city_list;
                  }
                });
            }
          } else {
            Swal.fire("Oops...", "Please do map integration!", "error");
            //return false;
          }
          this.couchdetail();
          // this.spinner.hide();
        });
    } else if (course == "CoursCollectifOndemand") {
      var coachId = {
        Coach_ID: coach.Coach_Id,
        indv_id: coach.Group_Id,
      };

      this.appService
        .getAll("/course/getcousecollectivedemanad", coachId)
        .subscribe((response) => {
          if ((response as any).data.course.length > 0) {
            if (response && response["data"]) {
              var dat = (response as any).data.course[0];
              this.Description = dat.Description;
              this.Mode_of_Transport=dat.Mode_of_transport;
              this.course_demand.person = dat.Max_People;
              this.course_demand.Price_2pl_1hr = dat.Price_2pl_1hr;
              this.course_demand.Price_3pl_1hr = dat.Price_3pl_1hr;
              this.course_demand.Price_4pl_1hr = dat.Price_4pl_1hr;
              this.course_demand.Price_5pl_1hr = dat.Price_5pl_1hr;
              this.course_demand.Price_6pl_1hr = dat.Price_6pl_1hr;
              this.course_demand.Price_2pl_10hr = dat.Price_2pl_10hr;
              this.course_demand.Price_3pl_10hr = dat.Price_3pl_10hr;
              this.course_demand.Price_4pl_10hr = dat.Price_4pl_10hr;
              this.course_demand.Price_5pl_10hr = dat.Price_5pl_10hr;
              this.course_demand.Price_6pl_10hr = dat.Price_6pl_10hr;
              this.pincode = dat.Postalcode;
              this.location = dat.Location;
              this.mapvalues = eval("[" + dat.coordonnees_gps + "]");
              this.lat = this.mapvalues[0].toFixed(3);
              this.lang = this.mapvalues[1].toFixed(3);
              this.mapintigration(this.mapvalues, this.location);
              this.appService
                .getAll("/city/" + dat.Postalcode)
                .subscribe((response) => {
                  if (response && response["data"]) {
                    this.selectedCity = (response as any).data.city_list;
                  }
                });
            }
          } else {
            Swal.fire("Oops...", "Please do map integration!", "error");
            //return false;
          }
          this.couchdetail();
        });
    } else if (course == "CoursCollectifClub") {
      this.appService
        .getAll("/course/getcousecollectiveclub", Coach_ID)
        .subscribe((response) => {
         
          if ((response as any).data.course.length > 0) {
            if (response && response["data"]) {
              var coach = JSON.parse(localStorage.getItem("Coach"));
              var Course_Id = coach.Course_Id;
              this.Courseavailablity = (response as any).data.availablity;
              this.availablity = this.Courseavailablity.filter(x => x.Course_Id == Course_Id);
              var dat = (response as any).data.course[0];
              this.price = dat.Price;
              this.Video = dat.Video;
              this.pincode = dat.Postalcode;
              this.location = dat.Place;
              this.Description = dat.Description;
              this.Mode_of_Transport=dat.Mode_of_Transport;
              this.mapvalues = eval("[" + dat.coordonnees_gps + "]");
              this.lat = this.mapvalues[0].toFixed(3);
              this.lang = this.mapvalues[1].toFixed(3);
              this.mapintigration(this.mapvalues, this.location);
              this.appService
                .getAll("/city/" + dat.Postalcode)
                .subscribe(response => {
                  if (response && response["data"]) {
                    this.selectedCity = (response as any).data.city_list;
                  }
                });
            }
          } else {
            Swal.fire("Oops...", "Please Contact to Administrator!", "error");
            //return false;
          }
        });
      this.couchdetail();
    } else {
      this.appService
        .getAll("/course/getcoachbyid", Coach_ID)
        .subscribe((response) => {
          if ((response as any).data.course.length > 0) {
            if (response && Object.keys(response["data"]).length != 0) {
              var dat = (response as any).data.course[0];
              this.price = dat.Price_min;
              this.Indiv_1hr = dat.Price_min;
              this.Indiv_10hr = dat.Price_max;
              this.Video = dat.Video;
              this.Description = dat.Description;
              this.pincode = dat.Postalcode;
              this.location = dat.Location;
              this.mapvalues = eval("[" + dat.coordonnees_gps + "]");
              this.lat = this.mapvalues[0].toFixed(3);
              this.lang = this.mapvalues[1].toFixed(3);
              this.mapintigration(this.mapvalues, this.location);
              this.appService
                .getAll("/city/" + dat.Postalcode)
                .subscribe((response) => {
                  if (response && response["data"]) {
                    this.selectedCity = (response as any).data.city_list;
                  }
                });
            }
          } else {
            Swal.fire("Oops...", "Please do map integration!", "error");
          }
          this.couchdetail();
        });
    }
  }

  onSubmitCoach() {
    var codepostal = this.coachdetailform.value.codepostal;
    localStorage.setItem("codepostal", codepostal);
    this.router.navigate(["/ohmycoachnew"]);
  }

  getReviewDetails(Course) {
    var coachid = localStorage.getItem("Coach_id");
    var coursedetails = {
      course: Course,
      Coach_Id: coachid,
    };

    this.appService
      .getAll("/user/getallreviews", coursedetails)
      .subscribe((response) => {
        if (response && response["data"]) {
          for (var i = 0; i < response["data"].length; i++) {
            let currentDate = new Date();
            let dateSent = new Date(response["data"][i].created_at);
            var noofdays = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
            var reviewdaycount = (noofdays == 0) ? "Aujourd'hui" : (noofdays == 1) ? "il y a jour" : noofdays + " il y a quelques jours";
            this.cutomerReviewCollection.push({
              comments: response["data"][i].comments,
              first_name: response["data"][i].firstName,
              last_name: response["data"][i].lastName,
              user_image: response["data"][i].User_Image,
              today_day: moment(new Date()).format("DD"),
              review_day: moment(response["data"][i].created_at).format("DD"),
              reviewdaycount: reviewdaycount,
            });
          }
          this.count_id = response["data"].length;
        }
      });
  }

  onSubmitComment() {

    var Comments = this.particulersform.value.comments;
    var course = localStorage.getItem("Course");
    var coachId = localStorage.getItem("Coach_id");
    var users = JSON.parse(localStorage.getItem("onmytennis"));
    var user = JSON.parse(users);
    var insertComments = {
      Coach_Id: coachId,
      User_Id: user.id,
      course: course,
      comments: Comments,
    };
    this.spinner.show();
    this.appService.create("/user/createreviews", insertComments).subscribe(
      (response) => {
        if (response && response.isSuccess == true) {
          this.showsuccessMsg = true;
          document
            .getElementById("btnreviews")
            .setAttribute("disabled", "true");
          this.cutomerReviewCollection = [];
          var course = localStorage.getItem("Course");
          this.getReviewDetails(course);
          this.spinner.hide();
        } else {
          this._showAlertMessage(
            "alert-danger",
            "Échec de l'inscription des entraîneurs"
          );
        }

      },
      (error) => { }
    );
   
  }

  gotoCouchaa(couch: any) {
        
    localStorage.setItem("CoachId", couch.id);
    localStorage.setItem("sendCoachDetails", couch.name);
    this.router.navigate(["/coachdetail"]);

  }

  gotoCouch(ser, res: any = {}) { 

    if (localStorage.getItem("onmytennis") !== null) {
      var data = JSON.stringify(res);
      localStorage.setItem("Coach", data);
      localStorage.setItem("Course", ser);
      localStorage.setItem("ohmycoach-menu-type", ser);
      this.router.navigate(["/ohmycoachservice", { 'course': ser }]);
    } else {
      this.router.navigate(["/login"]);
    }
    
  }

  getduserbookingfordemande(date){
    this.spinner.show();
    var coachid = JSON.parse(localStorage.getItem("Coach"));
    var data = {
      Coach_ID: coachid.Coach_Id,
      Date:date
    };
    this.bookedCoachSide = [];
    this.appService
      .getAll("/coach/getdemandavailabilityByCoach", data)
      .subscribe((data) => {
        this.spinner.hide();
        if ((data as any).data.availabilty.length > 0) {
          this.bookedCoachSide = (data as any).data.availabilty;
        }else{
          this.bookedCoachSide = [];
        }
      });

  }

  mapintigration(mappoint, cityname) {

    this.map = L.map("map", {
      center: mappoint,
      zoom: 16,
    });

    const tiles = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 25,
      }
    );

    tiles.addTo(this.map);
    var greenIcon = L.icon({
      iconUrl: "../assets/images/marker-icon.png",
    });
    L.marker(mappoint, { icon: greenIcon }).addTo(this.map).bindPopup(cityname).openPopup();

  }

  mapRerender(mappoint, cityname) { 

    this.map.remove();
    this.mapintigration(mappoint, cityname);

  }

  private refresh(): boolean {
    return this.searchQuery && this.searchQuery === this.previousSearchQuery;
  }

  public doRefresh(): void {
    this.routedComponent.refresh();
  }

  async getcurrentcordinates() {
    const resp = await fetch("https://ipapi.co/json/");
    const data = await resp.json();
    this.curentlat = data.latitude.toFixed(3);
    this.curentlang = data.longitude.toFixed(3);
  }

  mapurl;
  villeSelected(postal_code: string) {
    this.spinner.show();
    this.appService.getAll("/city/getcitybyID/" + postal_code).subscribe((response) => {
      this.spinner.hide();
      if (response && response["data"]) {
        console.log(response);
        var dat = (response as any).data.city_list[0];
        this.mapvalues = eval("[" + dat.coordonnees_gps + "]");
        this.lat = this.mapvalues[0].toFixed(3);
        this.lang = this.mapvalues[1].toFixed(3);
        this.mapurl = `https://www.openstreetmap.org/directions?engine=fossgis_osrm_car&route=${this.curentlat}%2C${this.curentlang}%3B${this.lat}%2C${this.lang}`;
        this.mapRerender(this.mapvalues, dat.Libelle_acheminement);
      }
    });
  }
  
  modelClose(){
    $('#priceModal').removeClass('in');
    $("#priceModal").css('display','none');
  }

  onEventClick(arg) {

    if(arg.event.classNames[0]=="userbookedcount"){
      let date=moment(arg.event.start).format('YYYY-MM-DD');
      this.getduserbookingfordemande(date);
      $('#priceModal').addClass('in');
      $("#priceModal").css('display','block');
    }else{
      let date=moment(arg.event.start).format('YYYY-MM-DD');
      let data = { dateStr:date };
      this.handleDateClick(data);
    }
   }

  handleDateClick(arg) {
    this.spinner.show();
    $(".day-highlight").removeClass("day-highlight");
    $(arg.dayEl).addClass("day-highlight");
    var course = localStorage.getItem("Course");
    var Coach_id = localStorage.getItem("Coach_id");
    var coach = JSON.parse(localStorage.getItem("Coach"));
    this.getduserbookingfordemande(arg.dateStr);
    var detail = {
      Start_Date: arg.dateStr,
      Coach_ID: Coach_id,
      Course: course,
    };

    this.bookingDate = arg.dateStr;
    this.Timeslotdata = {
      Start_Date: arg.dateStr,
      Coach_ID: Coach_id,
      Course: course,
    };

    var dataVal = [];
    for (let i = 0; i < this.bookArray.length; i++) {
      dataVal.push({
        date: this.bookArray[i].P_Date,
        time: this.bookArray[i].P_Hour,
      });
    }

    if (course != "CoursCollectifClub") {

      this.appService.getAll("/coach/getTimeslot", detail).subscribe((data) => {
        if (this.bookArray.length > 0) {
          var flag = false;
          var timeSlotDatas = [];
          var timeslotLength = (data as any).data.availabilty;

          for (let j = 0; j < timeslotLength.length; j++) {
            for (let k = 0; k < dataVal.length; k++) {
              if (
                dataVal[k].date == this.bookingDate &&
                dataVal[k].time == timeslotLength[j].description
              ) {
                flag = true;
              }
            }
            if (flag) {
              timeSlotDatas.push({
                SlotId: timeslotLength[j].SlotId,
                description: timeslotLength[j].description,
                Availability: timeslotLength[j].Availability,
                checked: true,
                BookCout :timeslotLength[j].BookCout,
              });
            } else {
              timeSlotDatas.push({
                SlotId: timeslotLength[j].SlotId,
                description: timeslotLength[j].description,
                Availability: timeslotLength[j].Availability,
                checked: false,
                BookCout :timeslotLength[j].BookCout,
              });
            }
            flag = false;
          }
          this.timeslot = [];
          this.timeslot = timeSlotDatas as any;
          this.timeslotdisplay = true;
        } else {
          this.timeslot = [];
          this.timeslot = (data as any).data.availabilty;
          this.timeslotdisplay = true;
        }

        if (this.bookArray.length >= 10 && this.is10Hr == true) {
          setTimeout(function () {
            let formInputItem = document
              .querySelectorAll(".timeslotselect")[0]
              .querySelectorAll("input");
            formInputItem.forEach(function (inputElement) {
              let mode = inputElement as HTMLInputElement;
              if (mode.dataset.availability == 'Y' && mode.type == "checkbox") {
                if (mode.checked == false) {
                  mode.disabled = true;
                }
              }
            });
            this.spinner.hide();
            // 
          }, 2000);

        
        } else {
          this.spinner.hide();
        }
      });
    } else {
      this.showclub = true;
      this.spinner.hide();
    }
  }

  handleClick(event: Event) {
    this.router.navigate(["/ohmycoach"]);
  }

  reserve() {
    this.price = 0;
    if (this.bookArray.length >= 9 && this.is10Hr == true) {
      $("#available").show();
      this.price = this.Indiv_10hr;
      this.reserveData = this.bookArray;
    } else if (this.bookArray.length < 10 && this.is10Hr == true) {
      $("#fentes").show();
    } else {
      $("#available").show();
      this.price = this.Indiv_1hr * this.bookArray.length;
      this.reserveData = this.bookArray;
    }
    this.booking.amount = this.price;
  }

  priceCCOD() {
    alert();
    $("#pricemodules").show();
   

  }

  addTimeslot(rowData, id) {

    //this.slotRowData = rowData;
    var course = localStorage.getItem("Course");
    //Single Select
    if (document.getElementById("reserve") != null)
      document.getElementById("reserve").removeAttribute("disabled");
    if (course == "CoursCollectifOndemand") {
      let formInputItem = document
        .querySelectorAll(".timeslotselect")[0]
        .querySelectorAll("input");
      formInputItem.forEach(function (inputElement) {
        // this.IsChecked = true;
        let mode = inputElement as HTMLInputElement;
        if (mode.type == "checkbox") {
          if (mode.checked == true && mode.id != "check_" + id) {
            mode.checked = false;
          } else if (mode.checked == false && mode.id == "check_" + id) {
            // this.IsChecked = false;
            document.getElementById("reserve").setAttribute("disabled", "true");
          }
        }
      });
    }
    var coachid = JSON.parse(localStorage.getItem("Coach"));
    var user = JSON.parse(localStorage.getItem("onmytennis"));
    var user1 = JSON.parse(user);

    if (course == "CoursIndividuel") {
      let formInputItem = document.getElementById(
        "check_" + id
      ) as HTMLInputElement;
      this.IsChecked = true;
      if (formInputItem.checked == true && formInputItem.id != "check_" + id) {
        formInputItem.checked = false;
        document.getElementById("reserve").setAttribute("disabled", "false");
      } else if (
        formInputItem.checked == false &&
        formInputItem.id == "check_" + id
      ) {
        this.IsChecked = false;
      }

      if (
        (this.bookArray.length == 1 && this.IsChecked == true) ||
        (this.bookArray.length == 2 && this.IsChecked == false)
      ) {
        $("#10hrposter").show();
      }

      var userId = user1.id;
      if (this.is10Hr == false) {
        var index = this.session.indexOf(
          rowData.description + "," + this.bookingDate
        );
        if (index > -1) {
          this.session.splice(index, 1);
          this.Amt = this.Amt - parseInt(this.price, 10);
        } else {
          this.session.push(rowData.description + "," + this.bookingDate);
          this.Amt = this.Amt + parseInt(this.price, 10);
        }
      } else {
        var index = this.session.indexOf(
          rowData.description + "," + this.bookingDate
        );
        if (index > -1) {
          this.session.splice(index, 1);
          this.Amt = this.price;
        } else {
          this.session.push(rowData.description + "," + this.bookingDate);
          this.Amt = this.price;
        }
      }
      this.booking.amount = this.Amt.toString();
      this.booking.session = this.session;
      this.booking.Coach_ID = coachid.Coach_Id;
      this.booking.bookingCourse = localStorage.getItem("Course");
      this.booking.user_Id = userId;
      this.temps = "";
      for (var i = 0; i < this.session.length; i++) {
        if (this.temps != "")
          this.temps = this.temps + "," + this.session[i].split(",")[0];
        else this.temps = this.session[i].split(",")[0];
      }

      this.moment_date = moment(this.bookingDate).format("DD-MM-YYYY");
      this.bookArray = [];
      for (var i = 0; i < this.session.length; i++) {
        var ses = this.session[i].split(",");
        this.book_coach.P_BookingCourseID = coachid.id;
        this.book_coach.P_UserId = this.booking.user_Id;
        this.book_coach.P_CoachId = this.booking.Coach_ID;
        this.book_coach.P_Amount = this.booking.amount;
        this.book_coach.P_CourseId = this.booking.bookingCourse;
        this.booking.user_Name = user1.firstName + " " + user1.lastName;
        this.book_coach.P_Hour = ses[0];
        this.book_coach.P_Date = ses[1];
        this.bookArray.push(this.book_coach);
        this.book_coach = {
          P_BookingCourseID: "",
          P_CoachId: "",
          P_CourseId: "",
          P_Date: "",
          P_Hour: "",
          P_UserId: "",
          P_Amount: "",
          P_Remarks: "",
        };
      }
      if (this.bookArray.length >= 10 && this.is10Hr == true) {
        let formInputItem = document
          .querySelectorAll(".timeslotselect")[0]
          .querySelectorAll("input");

        formInputItem.forEach(function (inputElement) {
          let mode = inputElement as HTMLInputElement;
          if (mode.type == "checkbox") {
            if (mode.dataset.availability == 'Y' && mode.checked == false) {
              mode.disabled = true;
            }
          }
        });
      } else {
        let formInputItem = document
          .querySelectorAll(".timeslotselect")[0]
          .querySelectorAll("input");
        formInputItem.forEach(function (inputElement) {
          let mode = inputElement as HTMLInputElement;
          if (mode.type == "checkbox") {
            if (mode.dataset.availability == 'Y' && mode.checked == false) {
              mode.disabled = false;
            }
          }
        });
      }
    } else if (course == "CoursCollectifOndemand" && this.IsChecked == true) {
      this.slot = rowData.description;
      var userId = user1.id;
      this.booking.amount = "";
      this.booking.session = rowData.description;
      this.booking.Coach_ID = coachid.Coach_Id;
      this.booking.bookingCourse = localStorage.getItem("Course");
      this.temps = rowData.description;
      this.booking.user_Name = user1.firstName + " " + user1.lastName;
      this.moment_date = moment(this.bookingDate).format("DD-MM-YYYY");
      this.booking.user_Id = userId;
      this.Amt = this.Amt - parseInt(this.price, 10);
      var data = {
        Coach_ID: coachid.Coach_Id,
        slot: rowData.description,
        date: this.bookingDate,
      };
      this.booked_user = [];
      this.appService
        .getAll("/coach/getdemandavailability", data)
        .subscribe((data) => {
          if ((data as any).data.availabilty.length > 0) {
            this.book_person = (data as any).data.availabilty.length;
            this.booked_user = (data as any).data.availabilty;
          }else{
            this.booked_user = [];
          }
          this.step_2 = true;
        });
      this.bookArray = [];
      this.book_coach.P_BookingCourseID = coachid.Group_Id;
      this.book_coach.P_UserId = this.booking.user_Id;
      this.book_coach.P_CoachId = this.booking.Coach_ID;
      this.book_coach.P_Amount = this.booking.amount;
      this.book_coach.P_CourseId = this.booking.bookingCourse;
      this.book_coach.P_Hour = rowData.description;
      this.book_coach.P_Date = this.bookingDate;
      this.bookArray.push(this.book_coach);
      this.book_coach = {
        P_BookingCourseID: "",
        P_CoachId: "",
        P_CourseId: "",
        P_Date: "",
        P_Hour: "",
        P_UserId: "",
        P_Amount: "",
        P_Remarks: "",
      };
    } else if (course == "CoursCollectifClub") {
      var userId = user1.id;
      var time =
        rowData.StartTime.replace(":00", "h") +
        "-" +
        rowData.EndTime.replace(":00", "h");
      this.Amt = this.Amt + parseInt(rowData.Price, 10);
      this.Clubcourse = rowData.Course;
      this.Weekday = rowData.Weekday;
      this.booking.amount = rowData.Price.toString();
      this.booking.session = this.session;
      this.booking.coach_Email = this.coach_detail.Coach_Email;
      this.booking.coach_Name =
      this.coach_detail.Coach_Fname + " " + this.coach_detail.Coach_Lname;
      this.booking.Coach_ID = coachid.Coach_Id;
      this.booking.user_Name = user1.firstName + " " + user1.lastName;
      this.temps = time;
      this.booking.bookingCourse = localStorage.getItem("Course");
      this.booking.bookingDate = this.formatDate(new Date());
      var date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      date.setMonth(5);
      date.setDate(30);
      this.booking.bookingDateRange =
        moment(new Date()).format("DD-MM-YYYY") +
        " au " +
        moment(date).format("DD-MM-YYYY");
      this.booking.user_Id = userId;
      this.bookArray = [];
      this.book_coach.P_BookingCourseID = coachid.Course_Id;
      this.book_coach.P_UserId = this.booking.user_Id;
      this.book_coach.P_CoachId = this.booking.Coach_ID;
      this.book_coach.P_Amount = this.booking.amount;
      this.book_coach.P_CourseId = this.booking.bookingCourse;
      this.book_coach.P_Hour = time;
      this.book_coach.P_Date = moment(new Date()).format("YYYY-MM-DD");
      this.book_coach.P_Remarks = rowData.Course;
      this.bookArray.push(this.book_coach);
      this.book_coach = {
        P_BookingCourseID: "",
        P_CoachId: "",
        P_CourseId: "",
        P_Date: "",
        P_Hour: "",
        P_UserId: "",
        P_Amount: "",
        P_Remarks: "",
      };
    }
  }

  closemodal() {
    this.spinner.show();
    this.revokeChanges();
    $("#available").hide();
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
    this.timeslot = [];
    this.appService
      .getAll("/coach/getTimeslot", this.Timeslotdata)
      .subscribe((data) => {
        this.timeslot = (data as any).data.availabilty;
        this.spinner.hide();
      });
    this.spinner.hide();
  }

  closeclub() {
    this.spinner.show();
    this.revokeChanges();
    $("#clubmodal").hide();
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
    this.spinner.hide();
  }

  revokeChanges() {
    this.booking = {
      Coach_ID: "",
      user_Id: "",
      payment_Id: 0,
      status: "",
      bookingDate: "",
      bookingCourse: "",
      amount: "",
      coach_Email: "",
      user_Email: "",
      coach_Name: "",
      user_Name: "",
      paymentStatus: "",
      session: [],
      bookingDateRange: "",
    };
    this.book_coach = {
      P_BookingCourseID: "",
      P_CoachId: "",
      P_CourseId: "",
      P_Date: "",
      P_Hour: "",
      P_UserId: "",
      P_Amount: "",
      P_Remarks: "",
    };
    this.Amt = 0;
    this.bookArray = [];
    this.session = [];
    this.moment_date = "";
    this.temps = "";
    this.is10Hr = false;

  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  bookCoach() {
    var req = {
      bookArray: this.bookArray,
      totalAmt: this.booking.amount,
      remaingStatus: this.is10Hr == true ? "Yes" : "No",
    };

    this.spinner.show();
    this.appService
      .create("/coach/setreservationfun", req)
      .subscribe((response) => {
        if (response && response.isSuccess == true) {
          $(".btnbookingCoursIndividual").hide();
          this._showAlertMessage("alert-success", "Cours réservé avec succès");
          setTimeout(function () {
            $("#available").hide();
          }, 3000);
        } else {
          this._showAlertMessage(
            "alert-danger",
            "La réservation du cours a échoué"
          );
        }
        this.spinner.hide();
      });
  }

  couchdetail() {
    this.spinner.show();
    var coach = JSON.parse(localStorage.getItem("Coach"));
    var coachId = {
      CoachId: coach.Coach_Id,
    };
    this.appService
      .create("/coach/getcoachbyid", coachId)
      .subscribe(async (response) => {
        if (response && Object.keys(response["data"]).length != 0) {
          this.coach_detail = response.data.coach_list[0];
          var transportData = this.coach_detail.Coach_transport.split(",");
          var temp = new Array();
          temp = this.coach_detail.Coach_payment_type.split(",");
          this.str = temp.join(",");
          this.spinner.hide();
        }
      });
  }

  download() {
    if (this.coach_detail.Coach_Resume) {
      var blob = this.dataURLtoBlob(this.coach_detail.Coach_Resume);
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.coach_detail.ResumeName;
      link.click();
    }
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(",");
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = window.atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {
      type: mime,
    });
  }

  coachSlider() {
    this.slides = {
      data: [],
      config: {
        slidesToShow: this.slidecnt,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 1000,
        arrows: true,
      },
    };
    var Data: any;
    this.appService.getAll("/coach/getallcoaches").subscribe((response) => {
      Data = response;
      Data.data.coach_list.forEach((element) => {
        this.slides.data.push({
          img: element.Coach_Image,
          name: element.Coach_Fname + " " + element.Coach_Lname,
          comment: element.Coach_Description,
        });
      });
    });
  }

  enable10h() {
    // this.price = this.Indiv_10hr;
    this.appService
      .getAll(
        "/coach/get_avail_ten_is_or_not/" +
        this.bookArray[0].P_CoachId +
        "/" +
        this.bookArray[0].P_Date
      )
      .subscribe((response) => {
        var getAvail = response as any;
        if (getAvail.data.ten == true) {
          this.is10Hr = true;
          $("#10hrposter").hide();
        } else {
          this.is10Hr = false;
          $("#10hrposter").hide();
          $("#actuellement").show();
        }
      });
  }

  hide10h() {
    // this.price = this.Indiv_1hr;
    this.is10Hr = false;
    $("#10hrposter").hide();
  }

  actuellement() {
    // this.price = this.Indiv_1hr;
    this.is10Hr = false;
    $("#actuellement").hide();
  }

  fentes() {
    // this.price = this.Indiv_1hr;
    this.is10Hr = true;
    $("#fentes").hide();
  }

  hr10more() {
    // this.price = this.Indiv_1hr;
    this.is10Hr = true;
    $("#hrtenmore").hide();
  }

  openURL() {
    window.open(this.Video);
  }

  _showAlertMessage(c: string, t: string): void {
    $(".alert-dismissible").show();
    this.alertMsg.type = c;
    this.alertMsg.msg = t;
    this.alertMsg.show = true;
    setTimeout(function () {
      if ($(".alert-dismissible").css("display") == "block")
        $(".alert-dismissible").hide();
    }, 3000);
  }

  _closeAlertMessage(e) {
    if (e) {
      this.alertMsg.type = "";
      this.alertMsg.msg = "";
      this.alertMsg.show = false;
    }
  }

  cancelAction() {
    this.fileInput.nativeElement.click();
  }
}
