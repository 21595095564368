export const CONST = Object.freeze({
  SESSION_NAME: "onmytennis",
  VERSION: "",
  PATH: {
    LOGIN: "login",
    SOCIAL_USER: "socialuser",
    COACH_REGISTER: "coachregister",
    USER_REGISTER: "userregister",
    COACH_MODULE: "coach",
    OH_MY_COACH: "ohmycoach",
    OH_MY_COACH_NEW: "cours-particulier-tennis",
    //OH_MY_COACH_NEW: "ohmycoachnew",
    OH_MY_COACH_SERVICE: "ohmycoachservice",
    //OH_MY_EVENT_NEW: "ohmyeventnew",
    OH_MY_EVENT_NEW: "stage-tennis",
    OH_MY_EVENT: "ohmyevent",
    CGU: "cgu",
    CGV: "cgv",
    // REJOINDRE_LA_TEAM: "prof-particulier-tennis",
    DEVENIR_COACH: "prof-particulier-tennis",
    MENTIONS_LEGALES: "mentionslegales",
    COURDETENNIS: "cours-de-tennis",
    FAQ: "professeur-particulier-de-tennis",
    ENSAVIORPLUS: "cours-tennis-en-ligne",
    NEWSLETTERS: "actualité-tennis",
    RECRUTEMENT: "recrutement-cours-de-tennis",
    QUISOMMENOUS: "professeurs-tennis",
    OH_MY_COACH_DETAIL: "ohmycoachdetail",
    COACH_SLOT: "coachslot",
    CONTACT: "contact-tennis",
    STAGE: "stage",
    STAGE_DETAIL: "stage-detail",
    TOUNAMENT: "tournament",
    COMMENTAIRES: "Commentaires",
    TOUNAMENT_DETAIL: "tournament-detail",
    TEAMBUILDING: "team-building",
    TEAMBUILDING_DETAIL: "team-building-detail",
    ANIMATION: "animation",
    ANIMATION_DETAIL: "animation-detail",
    COUCH_DETAIL: "coachdetail",
    USER_MODULE: "user",
    FORGET_PASSWORD: "forgetpassword",
    SET_NEW_PASSWORD: "setnewpassword",
    OFFREDESERVICE: "offre-de-service-coach-tennis",
    OHMYEVENTSSERVICE :'ohmtevents-service-coach-tennis',
    OH_MY_COACH_DETAIL_NEW: "coach-detail-new",
    GUIDE_DES_TERRAINS_PRATIQUE: "guide-des-terrains-pratique",
    SITE_MAP: "sitemap",
    PRATIQUE_LICENSE: "pratique-license",
    ALL_TOUNAMENT: "coach-tounament",
    ALL_TEAMBUILDING: "coach-teambuilding",
    ALL_ANIMATIONS: "coach-animations",
    ALL_STAGE: "coach-stage",
    TERMS_CONDITION: "terms-condition",
    CONCEPTS: {
      SELF: "concept-cours-tennis",
      CONCEPT: {
        SELF: "concept-cours-tennis",
        TITLE: "Concept"
      },
      PRESENTATION: {
        SELF: "concept-présentation-cours-de-tennis",
        TITLE: "Presentation"
      },
      INFORMATIONS: {
        SELF: "concept-informations",
        TITLE: "Informations"
      },
      ENGAGEMENTS: {
        SELF: "concept-charte-engagement-cours-tennis",
        TITLE: "Engagements"
      },
      LACHARTE: {
        SELF: "concept-trouver-professeurs-tennis",
        TITLE: "Lacharte"
      },
      CHARTE: {
        SELF: "charte-qualité-cours-de-tennis",
        TITLE: "Charte qualité"
      }
    },
    CONSEILS: {
      SELF: "oh-my-conseils-cours-tennis",
      CONSEILS: {
        SELF: "oh-my-conseils-cours-tennis",
        TITLE: "Conseils"
      },
      PREMIEREPARTIE: {
        SELF: "conseil-premiere-partie-debutant-tennis",
        TITLE: "Premierepartie"
      },
      PREMIERCOURS: {
        SELF: "premier-cours-tennis",
        TITLE: "Premiercours"
      },
      PREMIERMATCH: {
        SELF: "premier-match-tennis",
        TITLE: "Premiermatch"
      },
      PREMIERTOURNOI: {
        SELF: "premier-tournois-tennis",
        TITLE: "premiertournoi"
      },
      DIETETIQUE: {
        SELF: "dietetique-tennis",
        TITLE: "Diététique"
      },
      FORMEPHYSIQUE: {
        SELF: "entrainement-physique-tennis",
        TITLE: "Formephysique"
      },
      CHOISIR: {
        SELF: "choix-materiel-tennis",
        TITLE: "Choisir"
      },
      TESTER: {
        SELF: "tester-materiel-accessoire-tennis",
        TITLE: "Choisir"
      }
    },
    TENNIS: {
      SELF:"tennis-débutant",
      TENNIS: {
        SELF:"tennis-débutant",
        TITLE: "Tennis"
      },
      HISTOIRE: {
        SELF:"histoire-du-tennis",
        TITLE: "Tennis Comprendre Histoire"
      },
      HISTOIREFIRST: {
        SELF: "histoire1880-1930",
        TITLE: "HISTOIRE DU TENNIS 1880-1930"
      },
      HISTOIRE1SECOND: {
        SELF: "histoire1930-1950",
        TITLE: "HISTOIRE DU TENNIS 1930-1950"
      },
      HISTOIRETHIRD: {
        SELF: "histoire1950-1973",
        TITLE: "HISTOIRE DU TENNIS 1950-1973"
      },
      HISTOIREFOUR: {
        SELF: "histoire-1973",
        TITLE: "HISTOIRE DU TENNIS 1973"
      },
      LEXIQUE: {
        SELF :"termes-de-tennis",
        TITLE: "Lexique"
      },
      LEXIQUEATOD: {
        SELF: "termes-de-tennis-lettre-a",
        TITLE: "Lexique A to D"
      },
      LEXIQUEETOM: {
        SELF: "lexique-de-tennis-lettre-e",
        TITLE: "Lexique E to M"
      },
      LEXIQUENTOQ: {
        SELF: "termes-de-tennis-lettre-n",
        TITLE: "Lexique N to Q"
      },
      LEXIQUERTOZ: {
        SELF: "lexique-de-tennis-lettre-r",
        TITLE: "Lexique R to Z"
      },
      REGLES: {
        SELF: "tennis-comprendre-regles",
        TITLE: "Tennis Comprendre Regles"
      },
      DIMENSIONS: {
        SELF: "dimensions-terrain-tennis",
        TITLE: "Regles dimensions"
      },
      COMPTER: {
        SELF: "historique-regles-tennis",
        TITLE: "Regles compter"
      },
      ELEMENTS: {
        SELF: "elements-court-tennis",
        TITLE: "Regles elements"
      },
      PRINCIPLE: {
        SELF: "reglementation-du-materiel-tennis",
        TITLE: "Regles principle"
      },
      CLASSEMENTTENNIS: {
        SELF: "classement-tennis",
        TITLE: "Classement tennis"
      },
      LICENCETENNIS: {
        SELF: "licence-tennis",
        TITLE: "Licence tennis"
      },
      APPRENDRETECHNIQUE: {
        SELF: "technique-tennis",
        TITLE: "Apprendre technique"
      },
      APPRENDRETACTIQUE: {
        SELF:"tactique-tennis",
        TITLE: "Apprendre tactique"
      },
      APPRENDREMENTAL: {
        SELF: "mental-tennis",
        TITLE: "Apprendre mental"
      },
      APPRENDREPHYSIQUE: {
        SELF : "physique-tennis",
        TITLE: "Apprendre physique"
      },
      PRATIQUER_VENTEDEMATERIEL : {
        SELF: "magasin-boutique-materiel-en-ligne-tennis",
        TITLE: "Vente de materiel"
      },
      PRATIQUER_GUIDETERRAINS  : {
        SELF: "guide-terrain-tennis",
        TITLE: "Guide terrains"
      },
      RECHERCHEPARTENAIRE : {
        SELF: "trouver-partenaires-tennis",
        TITLE: "Recherche partenaire"
      },
      PRATIQUER_TOURNOISAMICAUX  : {
        SELF: "tournoi-compétition-tennis-amical",
        TITLE: "Tournois amicaux"
      },
      PRATIQUER_TO_LICENCE  : {
        SELF: "icence-tennis",
        TITLE: "Licence"
      },
      PRATIQUER_TO_LESCLASSEMENTS  : {
        SELF: "calcul-classement-tennis",
        TITLE: "Les classements en france"
      },
      PRATIQUER_TO_COMPETITIONMODEEMPLOI  : {
        SELF: "tournoi-licence-compétition-tennis",
        TITLE: "Competition mode emploi"
      },
      PRATIQUER_TO_LESTOURNOISOPEN  : {
        SELF: "tournoi-tennis-fft",
        TITLE: "Les tournois open"
      },
      CIRCUITATP_DESCRIPTIF  : {
        SELF: "atp-actualité-tennis",
        TITLE: "Descriptif"
      },
      CIRCUITATP_BILLETTERIE  : {
        SELF: "billeterie-tennis",
        TITLE: "Circuitatp billetterie"
      },
      CIRCUITATP_PRONOSTICS  : {
        SELF: "pronostic-tennis",
        TITLE: "Circuitatp pronostics"
      },
      CIRCUITATP_LIVESCORE  : {
        SELF: "score-live-tennis",
        TITLE: "Circuitatp livescore"
      },
      CIRCUITATP_CLASSEMENT_ATP_ET_WTA  : {
        SELF: "classement-atp-wta-tennis",
        TITLE: "Circuitatp classement atp et wta"
      },
      CIRCUITATP_ACTUALITES  : {
        SELF: "actualite-info-tennis",
        TITLE: "Circuitatp actualites"
      }
    },
    ADMINS: {
      SELF: "admin",
      FORGOT: {
        SELF: "admin/forgotpassword",
        TITLE: "Admin Forgotpassword"
      },
      RESET: {
        SELF: "admin/resetpassword",
        TITLE: "Admin Restpassword"
      }
    },

    USERS: {
      SELF: "user",
      DASHBOARD: {
        SELF: "dashboard",
        TITLE: "Users Dashboard"
      },
      PROFILE: {
        SELF: "profile",
        TITLE: "profile"
      },
      RESERVATION: {
        SELF: "reservation",
        TITLE: "Users reservation"
      },
      NOTIFICATION: {
        SELF: "notification",
        TITLE: "notification"
      },
      REVIEWS: {
        SELF: "reviews",
        TITLE: "Users reviews"
      },
      PARTNER: {
        SELF: "partner",
        TITLE: "parter"
      },
      SPARRING: {
        SELF: "sparring",
        TITLE: "Users sparring"
      },
      CHANGEPASSWORD: {
        SELF: "chnagepassword",
        TITLE: "changepassword"
      },
      CMS: {
        SELF: "cms",
        TITLE: "Content Management System"
      },
      SINOUT: {
        SELF: "déconnexion",
        TITLE: "déconnexion"
      },
      DELETE_USER_ACCOUNT: {
        SELF: "deleteuseraccount",
        TITLE: "Delete User Account"
      },
    },
    COACH: {
      SELF: "coach",
      DASHBOARD: {
        SELF: "dashboard",
        TITLE: "Coach Dashboard"
      },
      PROFILE: {
        SELF: "profile",
        TITLE: "Coach Profile"
      },
      MY_CALENDER: {
        SELF: "calendar",
        TITLE: "My Calendar"
      },
      INDIVIDUAL_COURSE: {
        SELF: "individual-course",
        TITLE: "Individual Course"
      },
      RESERVATION: {
        SELF: "reservation",
        TITLE: "Reservation"
      },
      COURSECOLLECTION: {
        SELF: "CourseonDeman",
        TITLE: "CourseonDeman"
      },
      EDITCOURSECOLLECTION: {
        SELF: "CourseonDemanedit",
        TITLE: "CourseonDeman"
      },
      COURSECLUB: {
        SELF: "CourseOnClub",
        TITLE: "CourseOnClub"
      },
      EDITCOURSECLUB: {
        SELF: "CourseOnClubedit",
        TITLE: "CourseOnClub"
      },
      STAGE: {
        SELF: "Stage",
        TITLE: "Stage"
      },
      TEAMBUILDING: {
        SELF: "team-building",
        TITLE: "Teambuilding"
      },
      EDITTEAMBUILDING: {
        SELF: "Teambuildingedit",
        TITLE: "Teambuilding"
      },
      ANIMATION: {
        SELF: "Animation",
        TITLE: "Animation"
      },
      TOURNAMENT: {
        SELF: "Tounament",
        TITLE: "Tounament"
      },
      COMMENTAIRES: {
        SELF: "Commentaires",
        TITLE: "Commentaires"
      },
      EDITTOURNAMENT: {
        SELF: "Tounamentedit",
        TITLE: "Tounament"
      },
      EDITINDIVIDUAL: {
        SELF: "Individualedit",
        TITLE: "Individual"
      },
      EDITSTAGE: {
        SELF: "Stageedit",
        TITLE: "Stage"
      },
      EDITANIMATION: {
        SELF: "Animationedit",
        TITLE: "Animation"
      },
      DELETE_ACCOUNT: {
        SELF: "deleteaccount",
        TITLE: "Delete Account"
      },
      CHANGE_PASSWORD: {
        SELF: "chnagepassword",
        TITLE: "changepassword"
      }
    },
    USER: {
      SELF: "user"
    },
    ADMIN: {
      SELF: "admin",
      DASHBOARD: {
        SELF: "dashboards",
        TITLE: "Admin Dashboard"
      },
      COACHLIST: {
        SELF: "coachlist",
        TITLE: "Admin Coachlist"
      },
      COACHEDIT: {
        SELF: "coachedit",
        TITLE: "Admin Coachedit"
      },
      PROSPECTUSLIST: {
        SELF: "prospectuslist",
        TITLE: "Admin Prospectuslist"
      },
      PROSPECTEDIT: {
        SELF: "prospectedit",
        TITLE: "Admin Prospectusedit"
      },
      CLUBLIST: {
        SELF: "clublist",
        TITLE: "Admin Clublist"
      },
      CLUBEDIT: {
        SELF: "clubedit",
        TITLE: "Admin Clubledit"
      },
      ADMINPROFILE: {
        SELF: "profile",
        TITLE: "Admin Profile"
      },
      ADIMLIST: {
        SELF: "adminlist",
        TITLE: "Admin Adminlist"
      },
      ADMINEDIT: {
        SELF: "adminedit",
        TITLE: "Admin Adminedit"
      },
      ADMINCREATE: {
        SELF: "admincreate",
        TITLE: "Admin Admincreate"
      },
      ADMINCHANGEPASS: {
        SELF: "changepassword",
        TITLE: "Admin Changepassword"
      },
      PROFILE: {
        SELF: "profile",
        TITLE: "profile"
      },
      ADMINSERVICE: {
        SELF: "service",
        TITLE: "Admin Service"
      },
      CHANGEPASSWORD: {
        SELF: "chnagepassword",
        TITLE: "changepassword"
      },
      SINOUT: {
        SELF: "déconnexion",
        TITLE: "déconnexion"
      },
      PAYMENTS: {
        SELF: "payments",
        TITLE: "payments"
      },
      DYNAMICMENU: {
        SELF: "dynamic-menu",
        TITLE: "Dynamic Menu"
      },
      DYNAMICMENUFORM: {
        SELF: "dynamic-menu-form",
        TITLE: "Dynamic Menu Form"
      },
      EDITDYNAMICMENUFORM: {
        SELF: "edit-menu-form",
        TITLE: "Edit Dynamic Menu Form"
      },
      CMS: {
        SELF: "cms",
        TITLE: "Content Management System"
      },
      CMSFORM: {
        SELF: "cmsform",
        TITLE: "Add - Content Management System"
      },
      PAYMENTSDETAILS: {
        SELF: "paymentdetails",
        TITLE: "payment details"
      },
      PAID: {
        SELF: "paiddetails",
        TITLE: "paid details"
      }
    },
    STATICPAGE: {
      SELF: ""
    },
    GENERAL: {
      SELF: ""
    },
  }
});


export const RoutingPath = {
  LOGIN: "login",
  SOCIAL_USER: "socialuser",
  COACH_REGISTER: "coachregister",
  USER_REGISTER: "userregister",
  COACH_MODULE: "coach",
  OH_MY_COACH: "ohmycoach",
  OH_MY_COACH_NEW: "cours-particulier-tennis",
  //OH_MY_COACH_NEW: "ohmycoachnew",
  OH_MY_COACH_SERVICE: "ohmycoachservice",
  // OH_MY_EVENT_NEW: "ohmyeventnew",
  OH_MY_EVENT_NEW: "stage-tennis",
  OH_MY_EVENT: "ohmyevent",
  CGU: "cgu",
  CGV: "cgv",
  // REJOINDRE_LA_TEAM: "prof-particulier-tennis",
  DEVENIR_COACH: "prof-particulier-tennis",
  MENTIONS_LEGALES: "mentionslegales",
  COURDETENNIS: "cours-de-tennis",
  FAQ: "professeur-particulier-de-tennis",
  ENSAVIORPLUS: "cours-tennis-en-ligne",
  NEWSLETTERS: "actualité-tennis",
  RECRUTEMENT: "recrutement-cours-de-tennis",
  QUISOMMENOUS: "professeurs-tennis",
  OH_MY_COACH_DETAIL: "ohmycoachdetail",
  COACH_SLOT: "coachslot",
  CONTACT: "contact-tennis",
  STAGE: "stage",
  STAGE_DETAIL: "stage-detail",
  TOUNAMENT: "tournament",
  COMMENTAIRES: "Commentaires",
  TOUNAMENT_DETAIL: "tournament-detail",
  TEAMBUILDING: "team-building",
  TEAMBUILDING_DETAIL: "team-building-detail",
  ANIMATION: "animation",
  ANIMATION_DETAIL: "animation-detail",
  COUCH_DETAIL: "coachdetail",
  USER_MODULE: "user",
  FORGET_PASSWORD: "forgetpassword",
  SET_NEW_PASSWORD: "setnewpassword",

  OFFREDESERVICE: "offre-de-service-coach-tennis",
  OHMYEVENTSSERVICE :'ohmtevents-service-coach-tennis',
  OH_MY_COACH_DETAIL_NEW: "coach-detail-new",
  GUIDE_DES_TERRAINS_PRATIQUE: "guide-des-terrains-pratique",
  SITE_MAP: "sitemap",
  PRATIQUE_LICENSE: "pratique-license",
  ALL_TOUNAMENT: "coach-tounament",
  ALL_TEAMBUILDING: "coach-teambuilding",
  ALL_ANIMATIONS: "coach-animations",
  ALL_STAGE: "coach-stage",
  TERMS_CONDITION: "terms-condition",
  CONCEPTS: {
    SELF: "concept-cours-tennis",
    CONCEPT: {
      SELF: "concept-cours-tennis",
      TITLE: "Concept"
    },
    PRESENTATION: {
      SELF: "concept-présentation-cours-de-tennis",
      TITLE: "Presentation"
    },
    INFORMATIONS: {
      SELF: "concept-informations",
      TITLE: "Informations"
    },
    ENGAGEMENTS: {
      SELF: "concept-charte-engagement-cours-tennis",
      TITLE: "Engagements"
    },
    LACHARTE: {
      SELF: "concept-trouver-professeurs-tennis",
      TITLE: "Lacharte"
    },
    CHARTE: {
      SELF: "charte-qualité-cours-de-tennis",
      TITLE: "Charte qualité"
    }
  },
  CONSEILS: {
    SELF: "oh-my-conseils-cours-tennis",
    CONSEILS: {
      SELF: "oh-my-conseils-cours-tennis",
      TITLE: "Conseils"
    },
    PREMIEREPARTIE: {
      SELF: "conseil-premiere-partie-debutant-tennis",
      TITLE: "Premierepartie"
    },
    PREMIERCOURS: {
      SELF: "premier-cours-tennis",
      TITLE: "Premiercours"
    },
    PREMIERMATCH: {
      SELF: "premier-match-tennis",
      TITLE: "Premiermatch"
    },
    PREMIERTOURNOI: {
      SELF: "premier-tournois-tennis",
      TITLE: "premiertournoi"
    },
    DIETETIQUE: {
      SELF: "dietetique-tennis",
      TITLE: "Diététique"
    },
    FORMEPHYSIQUE: {
      SELF: "entrainement-physique-tennis",
      TITLE: "Formephysique"
    },
    CHOISIR: {
      SELF: "choix-materiel-tennis",
      TITLE: "Choisir"
    },
    TESTER: {
      SELF: "conseils-matriel-tester",
      TITLE: "Choisir"
    }
  },
  TENNIS: {
    SELF:"tennis-débutant",
    TENNIS: {
      SELF:"tennis-débutant",
      TITLE: "Tennis"
    },
    HISTOIRE: {
      SELF: "histoire-du-tennis",
      TITLE: "Tennis Comprendre Histoire"
    },
    HISTOIREFIRST: {
      SELF: "histoire1880-1930",
      TITLE: "HISTOIRE DU TENNIS 1880-1930"
    },
    HISTOIRE1SECOND: {
      SELF: "histoire1930-1950",
      TITLE: "HISTOIRE DU TENNIS 1930-1950"
    },
    HISTOIRETHIRD: {
      SELF: "histoire1950-1973",
      TITLE: "HISTOIRE DU TENNIS 1950-1973"
    },
    HISTOIREFOUR: {
      SELF: "histoire-1973",
      TITLE: "HISTOIRE DU TENNIS 1973"
    },
    LEXIQUE: {
      SELF :"termes-de-tennis",
      TITLE: "Lexique"
    },
    LEXIQUEATOD: {
      SELF: "termes-de-tennis-lettre-a",
      TITLE: "Lexique A to D"
    },
    LEXIQUEETOM: {
      SELF: "lexique-de-tennis-lettre-e",
      TITLE: "Lexique E to M"
    },
    LEXIQUENTOQ: {
      SELF: "termes-de-tennis-lettre-n",
      TITLE: "Lexique N to Q"
    },
    LEXIQUERTOZ: {
      SELF: "lexique-de-tennis-lettre-r",
      TITLE: "Lexique R to Z"
    },
    REGLES: {
      SELF: "tennis-comprendre-regles",
      TITLE: "Tennis Comprendre Regles"
    },
    DIMENSIONS: {
      SELF: "dimensions-terrain-tennis",
      TITLE: "Regles dimensions"
    },
    COMPTER: {
      SELF: "historique-regles-tennis",
      TITLE: "Regles compter"
    },
    ELEMENTS: {
      SELF: "elements-court-tennis",
      TITLE: "Regles elements"
    },
    PRINCIPLE: {
      SELF: "reglementation-du-materiel-tennis",
      TITLE: "Regles principle"
    },
    CLASSEMENTTENNIS: {
      SELF: "classement-tennis",
      TITLE: "Classement tennis"
    },
    LICENCETENNIS: {
      SELF: "licence-tennis",
      TITLE: "Licence tennis"
    },
    APPRENDRETECHNIQUE: {
      SELF: "technique-tennis",
      TITLE: "Apprendre technique"
    },
    APPRENDRETACTIQUE: {
      SELF:"tactique-tennis",
      TITLE: "Apprendre tactique"
    },
    APPRENDREMENTAL: {
      SELF: "mental-tennis",
      TITLE: "Apprendre mental"
    },
    APPRENDREPHYSIQUE: {
      SELF : "physique-tennis",
      TITLE: "Apprendre physique"
    },
    PRATIQUER_VENTEDEMATERIEL : {
        SELF: "magasin-boutique-materiel-en-ligne-tennis",
        TITLE: "Vente de materiel"
      },
      PRATIQUER_GUIDETERRAINS  : {
        SELF: "guide-terrain-tennis",
        TITLE: "Guide terrains"
      },
      RECHERCHEPARTENAIRE : {
        SELF: "trouver-partenaires-tennis",
        TITLE: "Recherche partenaire"
      },
      PRATIQUER_TOURNOISAMICAUX  : {
        SELF: "tournoi-compétition-tennis-amical",
        TITLE: "Tournois amicaux"
      },
      PRATIQUER_TO_LICENCE  : {
        SELF: "icence-tennis",
        TITLE: "Licence"
      },
      PRATIQUER_TO_LESCLASSEMENTS  : {
        SELF: "calcul-classement-tennis",
        TITLE: "Les classements en france"
      },
      PRATIQUER_TO_COMPETITIONMODEEMPLOI  : {
        SELF: "tournoi-licence-compétition-tennis",
        TITLE: "Competition mode emploi"
      },
      PRATIQUER_TO_LESTOURNOISOPEN  : {
        SELF: "tournoi-tennis-fft",
        TITLE: "Les tournois open"
      },
      CIRCUITATP_DESCRIPTIF  : {
        SELF: "atp-actualité-tennis",
        TITLE: "Descriptif"
      },
      CIRCUITATP_BILLETTERIE  : {
        SELF: "billeterie-tennis",
        TITLE: "Circuitatp billetterie"
      },
      CIRCUITATP_PRONOSTICS  : {
        SELF: "pronostic-tennis",
        TITLE: "Circuitatp pronostics"
      },
      CIRCUITATP_LIVESCORE  : {
        SELF: "score-live-tennis",
        TITLE: "Circuitatp livescore"
      },
      CIRCUITATP_CLASSEMENT_ATP_ET_WTA  : {
        SELF: "classement-atp-wta-tennis",
        TITLE: "Circuitatp classement atp et wta"
      },
      CIRCUITATP_ACTUALITES  : {
        SELF: "actualite-info-tennis",
        TITLE: "Circuitatp actualites"
      }
  },
  ADMINS: {
    SELF: "admin",
    FORGOT: {
      SELF: "admin/forgotpassword",
      TITLE: "Admin Forgotpassword"
    },
    RESET: {
      SELF: "admin/resetpassword",
      TITLE: "Admin Restpassword"
    }
  },

  USERS: {
    SELF: "user",
    DASHBOARD: {
      SELF: "dashboard",
      TITLE: "Users Dashboard"
    },
    PROFILE: {
      SELF: "profile",
      TITLE: "profile"
    },
    RESERVATION: {
      SELF: "reservation",
      TITLE: "Users reservation"
    },
    NOTIFICATION: {
      SELF: "notification",
      TITLE: "notification"
    },
    REVIEWS: {
      SELF: "reviews",
      TITLE: "Users reviews"
    },
    PARTNER: {
      SELF: "partner",
      TITLE: "parter"
    },
    SPARRING: {
      SELF: "sparring",
      TITLE: "Users sparring"
    },
    CHANGEPASSWORD: {
      SELF: "chnagepassword",
      TITLE: "changepassword"
    },
    CMS: {
      SELF: "cms",
      TITLE: "Content Management System"
    },
    SINOUT: {
      SELF: "déconnexion",
      TITLE: "déconnexion"
    },
    DELETE_USER_ACCOUNT: {
      SELF: "deleteuseraccount",
      TITLE: "Delete User Account"
    }
  },
  COACH: {
    SELF: "coach",
    DASHBOARD: {
      SELF: "dashboard",
      TITLE: "Coach Dashboard"
    },
    PROFILE: {
      SELF: "profile",
      TITLE: "Coach Profile"
    },
    MY_CALENDER: {
      SELF: "calendar",
      TITLE: "My Calendar"
    },
    INDIVIDUAL_COURSE: {
      SELF: "individual-course",
      TITLE: "Individual Course"
    },
    RESERVATION: {
      SELF: "reservation",
      TITLE: "Reservation"
    },
    COURSECOLLECTION: {
      SELF: "CourseonDeman",
      TITLE: "CourseonDeman"
    },
    EDITCOURSECOLLECTION: {
      SELF: "CourseonDemanedit",
      TITLE: "CourseonDeman"
    },
    COURSECLUB: {
      SELF: "CourseOnClub",
      TITLE: "CourseOnClub"
    },
    EDITCOURSECLUB: {
      SELF: "CourseOnClubedit",
      TITLE: "CourseOnClub"
    },
    STAGE: {
      SELF: "Stage",
      TITLE: "Stage"
    },
    TEAMBUILDING: {
      SELF: "team-building",
      TITLE: "Teambuilding"
    },
    EDITTEAMBUILDING: {
      SELF: "Teambuildingedit",
      TITLE: "Teambuilding"
    },
    ANIMATION: {
      SELF: "Animation",
      TITLE: "Animation"
    },
    TOURNAMENT: {
      SELF: "Tounament",
      TITLE: "Tounament"
    },
    COMMENTAIRES: {
      SELF: "Commentaires",
      TITLE: "Commentaires"
    },
    EDITTOURNAMENT: {
      SELF: "Tounamentedit",
      TITLE: "Tounament"
    },
    EDITINDIVIDUAL: {
      SELF: "Individualedit",
      TITLE: "Individual"
    },
    EDITSTAGE: {
      SELF: "Stageedit",
      TITLE: "Stage"
    },
    EDITANIMATION: {
      SELF: "Animationedit",
      TITLE: "Animation"
    },
    DELETE_ACCOUNT: {
      SELF: "deleteaccount",
      TITLE: "Delete Account"
    },
    CHANGE_PASSWORD: {
      SELF: "chnagepassword",
      TITLE: "changepassword"
    }
  },
  USER: {
    SELF: "user"
  },
  STATICPAGE: {
    SELF: ""
  },
  GENERAL: {
    SELF: ""
  },
  ADMIN: {
    SELF: "admin",
    DASHBOARD: {
      SELF: "dashboard",
      TITLE: "Admin Dashboard"
    },
    COACHLIST: {
      SELF: "coachlist",
      TITLE: "Admin Coachlist"
    },
    COACHEDIT: {
      SELF: "coachedit",
      TITLE: "Admin Coachedit"
    },
    PROSPECTUSLIST: {
      SELF: "prospectuslist",
      TITLE: "Admin Prospectuslist"
    },
    PROSPECTEDIT: {
      SELF: "prospectedit",
      TITLE: "Admin Prospectusedit"
    },
    CLUBLIST: {
      SELF: "clublist",
      TITLE: "Admin Clublist"
    },
    CLUBEDIT: {
      SELF: "clubedit",
      TITLE: "Admin Clubledit"
    },
    ADMINPROFILE: {
      SELF: "profile",
      TITLE: "Admin Profile"
    },
    ADIMLIST: {
      SELF: "adminlist",
      TITLE: "Admin Adminlist"
    },
    ADMINEDIT: {
      SELF: "adminedit",
      TITLE: "Admin Adminedit"
    },
    ADMINCREATE: {
      SELF: "admincreate",
      TITLE: "Admin Admincreate"
    },
    ADMINCHANGEPASS: {
      SELF: "changepassword",
      TITLE: "Admin Changepassword"
    },
    PROFILE: {
      SELF: "profile",
      TITLE: "profile"
    },
    ADMINSERVICE: {
      SELF: "service",
      TITLE: "Admin Service"
    },
    CHANGEPASSWORD: {
      SELF: "chnagepassword",
      TITLE: "changepassword"
    },
    SINOUT: {
      SELF: "déconnexion",
      TITLE: "déconnexion"
    },
    PAYMENTS: {
      SELF: "payments",
      TITLE: "payments"
    },
    DYNAMICMENU: {
      SELF: "dynamic-menu",
      TITLE: "Dynamic Menu"
    },
    DYNAMICMENUFORM: {
      SELF: "dynamic-menu-form",
      TITLE: "Dynamic Menu Form"
    },
    EDITDYNAMICMENUFORM: {
      SELF: "edit-menu-form",
      TITLE: "Edit Dynamic Menu Form"
    },
    CMS: {
      SELF: "cms",
      TITLE: "Content Management System"
    },
    CMSFORM: {
      SELF: "cmsform",
      TITLE: "Add - Content Management System"
    },
    PAYMENTSDETAILS: {
      SELF: "paymentdetails",
      TITLE: "payment details"
    },
    PAID: {
      SELF: "paiddetails",
      TITLE: "paid details"
    }
  }
}