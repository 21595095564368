import { Component, OnInit, AfterViewChecked } from "@angular/core";
import {
  FormsModule,
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormArray
} from "@angular/forms";
import { Location } from "@angular/common";
import { BrowserModule, Title, Meta } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppService } from "../../shared/app.service";
import { AppComponent } from "../../app.component";
/* [ Spinner ] */
import { NgxSpinnerService } from "ngx-spinner";
import { async } from "q";
declare const $: any;

@Component({
  selector: "app-social-user",
  templateUrl: "./social-user.component.html",
  styleUrls: ["./social-user.component.scss"]
})
export class SocialUserComponent extends AppComponent implements OnInit {
  public newUser: any;
  public amt = 0;
  public errorMessage: any;
  public selectedCity: any = null;
  public cityId: any = "";
  public userForm: FormGroup;
  passwordfield;
  showPassword = false;

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    appService: AppService,
    location: Location,
    spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    super(activatedRoute, router, appService, location, spinner);
  }

  ngOnInit() {

    this.userForm = this.formBuilder.group({
      
      roleId: ['', Validators.required],
      mobile: ['', Validators.required],
      postalCode: ['', Validators.required],
      cityId: ['', Validators.required],
      termsCondition: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%#*?&].{8,}')
      ]]
      
    });

    const id: string = this.activatedRoute.snapshot.queryParamMap.get("id");
    if (id) {
      var email = atob(id);

      this.appService
        .create("/user/userVerification", { email: email })
        .subscribe(
          response => {
            console.log("verify", response);
            this._showAlertMessage("alert-success", response.message);
          },
          error => {
            this._showAlertMessage("alert-danger", error.message);
          }
        );
    }

    const bookid: string = this.activatedRoute.snapshot.queryParamMap.get(
      "bookid"
    );
    if (bookid) {
      this.appService
        .getAll("/coach/BookingDetail?booking_Id=" + bookid, this.newUser)
        .subscribe(response => {
          var res: any = response;
          this.amt = res.data.availabilty[0].amount;
          this.pay(
            this.appService,
            res.data.availabilty[0].amount,
            res.data.availabilty[0].user_Email,
            bookid
          );
        });
    }

    this.newUser = {
      email: "",
      password: ""
    };

    this.passwordfield = "password";
  }

  onClickPasswordfield() {
    if (this.passwordfield === "password") {
      this.passwordfield = "text";
      this.showPassword = true;
    } else {
      this.passwordfield = "password";
      this.showPassword = false;
    }
  }

  onSubmitUser() {
      //this.spinner.show();
      var formvalue = this.userForm.value
      var socialUser = JSON.parse(localStorage.getItem("socialUser"));
      var parameters = {
        provider_id: socialUser.provider_id,
        firstName: socialUser.firstName,
        lastName: socialUser.lastName,
        email: socialUser.email,
        provider: socialUser.provider,
        User_Image: socialUser.User_Image,
        roleId:formvalue.roleId,
        mobile:formvalue.mobile,
        postalCode:formvalue.postalCode,
        cityId:formvalue.cityId,
        password:formvalue.password
      };
      
        localStorage.setItem('currentUser', JSON.stringify(parameters));
      
        this.appService.create('/user/social_user', parameters)
       .subscribe(response => {
         
         if (response && response.isSuccess == true) {
          //this.spinner.hide();
          if (
            this._setSession(
              "setItem",
              JSON.stringify(parameters)
            ) === true
          ) {
            this._gotoPath("/");
          }
           //this._showAlertMessage('alert-success', 'Utilisateur enregistré avec succès');
         } else {
           //this._showAlertMessage('alert-danger', "Impossible de continuer. S'il vous plaît essayer après un certain temps");
         }
         
       }, error => {
       });
  }

   numberOnly(event): boolean {
    if (event.target.value.length > 9) {
      return false;
    }
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  pay(appService, amount, email, bookid) {
    var data: any;
    this.closeModal();
    var handler = (<any>window).StripeCheckout.configure({
      key: "pk_test_ppbf90Eyy5PuXBdNQNLpxVuz00e719Y31R",
      locale: "fr",
      token: function(token: any) {
        if (token) {
          var coachemail = {
            status: "B",
            booking_id: bookid,
            amount: amount
          };
          appService
            .create("/coach/setpayment", coachemail)
            .subscribe(response => {});
          data = token;
        }
        alert("Payment success!!");
      }
    });
    this.setstatus(data);

    handler.open({
      name: "Oh My Tennis",
      description: "RESERVER UN COURS",
      email: email,
      amount: amount,
      currency: "EUR"
    });
  }

  private mdlSampleIsOpen: boolean = false;
  private Mytitle: string = "";
  private openModal(title): void {
    console.log(title);
    this.mdlSampleIsOpen = true;
  }

  private closeModal(): void {
    this.mdlSampleIsOpen = false;
  }
  setstatus(data) {
    if (data) {
      const bookid: string = this.activatedRoute.snapshot.queryParamMap.get(
        "bookid"
      );

      var details = {
        status: "B",
        booking_id: bookid,
        amount: this.amt
      };

      this.appService
        .create("/coach/setpayment", details)
        .subscribe(async val => {
          if (val.isSuccess == true) {
            this._showAlertMessage("alert-success", "Payment Successfully");
          } else {
            this._showAlertMessage("alert-danger", "Payment Failed");
          }
        });
    }
  }

  loadStripe() {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  searchCity(e) {
    if (e && e.target.value) {
      this.appService.getAll('/city/' + e.target.value)
        .subscribe((response) => {
          if (response && response['data']) {
            this.selectedCity = (response as any).data.city_list;
            // if (this.selectedCity.length > 0)
            //   this.cityId = this.selectedCity[0].id;
          }
        });
    }
  }

  get rForm() {
    return this.userForm.controls;
  }

}
