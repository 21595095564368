import { Component, OnInit } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, AuthService } from 'angular-6-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../shared/app.service';
import { AppComponent } from "../../app.component";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
  providers: [AuthService]
})
export class SocialLoginComponent extends AppComponent implements OnInit {

  constructor(

    activatedRoute: ActivatedRoute,
    router: Router,
    appService: AppService,
    location: Location,
    spinner: NgxSpinnerService,
    private socialAuthService: AuthService,

  ) {
    super(activatedRoute, router, appService, location, spinner);
  }

  public userPostData = {
    email: '',
    firstName: '',
    lastName: '',
    provider: '',
    provider_id: '',
    User_Image: '',
    roleId: ''
  };

  ngOnInit() {
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.apiConnection(userData);
    });

  }

  apiConnection(data) {
    this.userPostData.provider_id = data.id;
    this.userPostData.firstName = data.name.split(' ').slice(0, -1).join(' ');
    this.userPostData.lastName = data.name.split(' ').slice(-1).join(' ');
    this.userPostData.email = data.email;
    this.userPostData.provider = data.provider;
    this.userPostData.User_Image = data.image;
    localStorage.setItem('socialUser', JSON.stringify(this.userPostData));
    var userdetails = {
      email: this.userPostData.email,
      provider: this.userPostData.provider
    };
    this.appService.create('/user/register/check_social_user', userdetails)
      .subscribe(response => {
        this.spinner.show();
        if (response && response.data) {
          if (response.isSuccess == true) {
            localStorage.setItem('currentUser', JSON.stringify(response.data[0]));
            if (response.data.length !== 0) {
              if (response.data[0].roleId == 2) {
                if (
                  this._setSession(
                    "setItem",
                    JSON.stringify(response.data[0])
                  ) === true
                ) {

                  this._gotoPath("/");
                  setTimeout(() => {
                    this.spinner.hide();
                    this._gotoPath(this._const("PATH.COACH.SELF"));
                  }, 300);

                }
              } else if (response.data[0].roleId == 1) {
                if (
                  this._setSession(
                    "setItem",
                    JSON.stringify(response.data[0])
                  ) === true
                ) {
                  this.spinner.hide();
                  this._gotoPath("/");
                }
              }
            } else {
              this.router.navigate(["socialuser"]);
            }
            this.spinner.hide();
          } else if (response.isSuccess == false) {
            this._showAlertMessage("alert-danger", "Échec de la connexion");
            this.spinner.hide();
          }
        }
      }, error => {
      });
  }
}
